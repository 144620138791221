
import React from 'react'
import { func } from 'prop-types'
import { marketingUrlRoot } from 'constants/externalLinks'
import { FaAddressBook } from 'components/FontAwesome'

import {
  Contacts,
  Wrapper,
} from './styles'

const CopyrightFooter = ({ toggleCollapsedPopup }) => (
  <Wrapper>
    <span>
      {`© ${new Date().getFullYear()} Comsearch, A CommScope Company`}
    </span>
    <Contacts
      className={'margin-left-auto margin-right-1'}>
      {'Toll Free: '}
      <a
        href={'tel:1-800-318-1234'}
        target={'_self'}>
        {'+1 800 318 1234'}
      </a>
    </Contacts>
    <Contacts
      className={'margin-left-1 margin-right-1'}>
      {'Email: '}
      <a
        href='mailto:customersupport@comsearch.com'
        target='_self'
        rel='noopener noreferrer'>
        {'customersupport@comsearch.com'}
      </a>
    </Contacts>
    <div
      className={'margin-left-1 margin-right-1 cursor-pointer'}
      onClick={toggleCollapsedPopup}>
      <FaAddressBook />
    </div>
    <span className={'margin-left-auto'}>
      {`Current Version: ${process.env.VERSION} `}
    </span>
    <a
      className={'margin-left-1 margin-right-1'}
      href={`${marketingUrlRoot}/policies`}
      rel={'noopener noreferrer'}
      target={'_blank'}>
      {'Policies'}
    </a>
    <a
      href={`${marketingUrlRoot}/credits`}
      rel={'noopener noreferrer'}
      target={'_blank'}>
      {'Credits'}
    </a>
  </Wrapper>
)

CopyrightFooter.propTypes = {
  toggleCollapsedPopup: func.isRequired,
}

export default CopyrightFooter
