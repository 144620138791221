
import React from 'react'

import {
  ComsearchConnectVideoWrapper,
  IframeWrapper,
} from './styles'

const ComsearchConnectVideo = () => (
  <ComsearchConnectVideoWrapper>
    <h3>{'Welcome to Comsearch Connect!'}</h3>
    <IframeWrapper>
      <iframe
        allow={'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'}
        allowFullScreen={true}
        frameBorder={0}
        src={'https://www.youtube.com/embed/vr4dW7NkSa0'} />
    </IframeWrapper>
  </ComsearchConnectVideoWrapper>
)

export default ComsearchConnectVideo
