import React from 'react'
import { TooltipContextTrigger } from 'components/TooltipContext'

const getPasswordTooltipContent = () => (
  <span>
    {'Passwords must be '}<b>{'at least 8 '}</b>{'characters long and contain'}<b>{' at least:'}</b><br />
    <ul className={'padding-left-1'}>
      <li>{'one lowercase letter'}</li>
      <li>{'one uppercase letter'}</li>
      <li>{'one number'}</li>
      <li>{'one special character'}</li>
    </ul>
  </span>
)

export default {
  header: 'Sign Up',
  defaultCountryCode: '1',
  hideDefaults: true,
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: <>
        <span>{'Password'}</span>
        <TooltipContextTrigger
          getTooltipContent={getPasswordTooltipContent}
          id={'password-info'}
          triggerClasses={'margin-left-half font-size-1rem'} />
        </>,
      key: 'password',
      required: true,
      displayOrder: 6,
      type: 'password',
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 5,
      type: 'email',
    },
    {
      label: 'First Name',
      key: 'given_name',
      required: true,
      displayOrder: 3,
      type: 'string',
    },
    {
      label: 'Last name',
      key: 'family_name',
      required: true,
      displayOrder: 4,
      type: 'string',
    },
    {
      label: 'Phone number',
      key: 'phone_number',
      required: true,
      displayOrder: 2,
      type: 'string',
    },
  ],
}
