import React from 'react'
import { bool, func, node, string, object } from 'prop-types'
import withPopoverContext from './withPopoverContext'
import { FaQuestionCircle } from 'components/FontAwesome'

// NOTE - this component MUST be used within the context of the PopoverProvider!
// This is a wrapper for what you want to present as a popover trigger.
// This handles communication with PopoverContext. You just tell it the
// id, popoverTrigger (trigger itself) and
// getPopoverContent (function that returns the JSX for the popover.)

class PopoverContextTrigger extends React.PureComponent {
  static propTypes = {
    children: node,
    disabled: bool,
    id: string.isRequired,
    getPopoverContent: func.isRequired,
    triggerClasses: string,
    triggerPopover: func.isRequired,
    popoverOptions: object,
  }

  componentWillUnmount () {
    this.props.triggerPopover()
  }

  // This is specifically for a click event (BackButton).
  // onMouseOut sometimes doesn't work for click events
  handleMouseUp = () => this.props.triggerPopover()

  handleMouseOver = () => {
    const {
      disabled,
      id,
      getPopoverContent,
      triggerPopover,
      popoverOptions,
    } = this.props

    // if the parent tells us to be disabled, don't trigger the Popover.
    if (disabled) {
      return
    }
    // build the content for the popover that we're about to display.
    const content = getPopoverContent()

    // display the popover now.
    triggerPopover(id, content, popoverOptions)
  }

  render () {
    const {
      children,
      id,
      triggerClasses,
    } = this.props

    return (
      <span
        className={triggerClasses}
        id={id}
        onMouseOver={this.handleMouseOver}>
        {children || <FaQuestionCircle />}
      </span>
    )
  }
}

export default withPopoverContext(PopoverContextTrigger)
