import React from 'react'
import { bool, func, node, string } from 'prop-types'
import withTooltipContext from './withTooltipContext'
import { FaQuestionCircle } from 'components/FontAwesome'

// NOTE - this component MUST be used within the context of the TooltipProvider!
// This is a wrapper for what you want to present as a tooltip trigger.
// This handles communication with TooltipContext. You just tell it the
// id, tooltipTrigger (trigger itself) and
// getTooltipContent (function that returns the JSX for the tooltip.)

class TooltipContextTrigger extends React.PureComponent {
  static propTypes = {
    children: node,
    disabled: bool,
    id: string.isRequired,
    getTooltipContent: func.isRequired,
    triggerClasses: string,
    triggerTooltip: func.isRequired,
  }

  componentWillUnmount () {
    this.props.triggerTooltip()
  }

  // This is specifically for a click event (BackButton).
  // onMouseOut sometimes doesn't work for click events
  handleMouseUp = () => this.props.triggerTooltip()

  handleMouseOver = () => {
    const {
      disabled,
      id,
      getTooltipContent,
      triggerTooltip,
    } = this.props

    // if the parent tells us to be disabled, don't trigger the Tooltip.
    if (disabled) {
      return
    }

    // build the content for the tooltip that we're about to display.
    const content = getTooltipContent()

    // display the tooltip now.
    triggerTooltip(id, content)
  }

  render () {
    const {
      children,
      id,
      triggerClasses,
    } = this.props

    return (
      <span
        className={triggerClasses}
        id={id}
        onMouseOver={this.handleMouseOver}
        onMouseUp={this.handleMouseUp}>
        {children || <FaQuestionCircle />}
      </span>
    )
  }
}

export default withTooltipContext(TooltipContextTrigger)
